import gameConfig from '../../assets/config';
import starTexture from '../../assets/images/purchase/star_particle.png';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import UbuntuMedium from '../../assets/fonts/ubuntu/UBUNTU-M.ttf';

import flashlib from '../../assets/exported/gameMobile/FlashLib.json';

import eAnimationTypes from '../enums/eAnimationTypes';

import { getAssetsScale } from 'Engine/utils/getAssetsScale';
import eSoundType from '../sounds/eSoundType';
import Config from 'WrapperService/scaleManager/Config';

const scale = getAssetsScale({
  availableScales: ['0.7', '0.75', '0.8', '0.9'],
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
});

const assetsPath = `flashlib/gameMobile/AssetsCombined@${scale}x.json`;
const animationBasePath = 'animations/';

const commonAssets = [
  { name: eAnimationTypes.EAT_SYMBOLS, path: `${animationBasePath}symbols/symbols_royal_kitties.json`, extension: 'json' },
  { name: eAnimationTypes.EAT_PURCHASE_BUTTON, path: `${animationBasePath}bonusPurchaseButton/button_bones_pro.json`, extension: 'json' },
  { name: eAnimationTypes.EAT_CAT, path: `${animationBasePath}cat/cat_walking_rk.json`, extension: 'json' },
  { name: eAnimationTypes.EAT_BACKGROUND, path: `${animationBasePath}background/skeleton.json`, extension: 'json' },
  { name: 'BMFont', path: 'fonts/BMFont/BMFont.xml', extension: 'xml' },
  { name: 'starsParticle', path: starTexture, extension: 'png' }
];

// const desktopAssets = [
//   { name: eAnimationTypes.EAT_BACKGROUND, path: `${animationBasePath}background/skeleton.json`, extension: 'json' }
// ];

// const assetsConfig = EntryPoint.mobile ? commonAssets : [...commonAssets, ...desktopAssets];

const allAssets = [
  { name: 'GameAssets', path: assetsPath },
  { name: 'GameFlashLib', path: flashlib },
  { name: 'gameConfig', path: gameConfig },
  ...commonAssets
];

const delayedAssets = [
  { name: eAnimationTypes.EAT_BIG_WIN, path: `${animationBasePath}bigWins/dwb_rk.json`, extension: 'json' },
  { name: eAnimationTypes.EAT_PURCHASE, path: `${animationBasePath}bonusPurchase/purchase_bonuses.json`, extension: 'json' },
  { name: eAnimationTypes.EAT_ANTISIPATOR, path: `${animationBasePath}antisipator/antisipator_rk.json`, extension: 'json' }
];
const fonts = [
  { name: 'UbuntuBold', path: UbuntuBold },
  { name: 'UbuntuMedium', path: UbuntuMedium },
  { name: 'UbuntuRegular', path: UbuntuRegular }
];

export default {
  assets: allAssets,
  delayedAssets,
  fonts,
  sounds: eSoundType
};
