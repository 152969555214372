import FlashLib from "flashlib_onlyplay";
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import EntryPoint from 'Engine/EntryPoint';

export default class BackgroundContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.initAnimation();
        // this.active = false;
        this.addListeners();
    }

    addListeners() {
        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );
    }

    onResize (data) {
        if (data.isMobile && data.isPortrait) {
            this.goToFrame(1)
            // this.animation.state.setAnimation(0, eAnimationNames.EAN_BACKGROUND__PORTRAIT, true);
        } else {
            this.goToFrame(2)
        }
    }

    initAnimation() {
        this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_BACKGROUND);
        this.animation.state.setAnimation(0, eAnimationNames.EAN_BACKGROUND__LANDSCAPE, true);
        this.getChildByName('animationContainer').addChild(this.animation);
    }
}
