import FlashLib from 'flashlib_onlyplay';
import eAnimationTypes from '../../enums/eAnimationTypes';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationNames from '../../enums/eAnimationNames';

export default class ControllerPopupsBgAnimation extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
  }
  init() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_BIG_WIN);
    this.animation.visible = false;
    this.addChild(this.animation);
  }
  start() {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eAnimationNames.EAN_POPUP_BACKGROUND, true)
  }
  stop() {
    this.animation.visible = false;
  }
}
