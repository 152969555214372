export default {
  H: {
    offsets: {
      tournaments_panel: { top: 125, left: -1430 }
    }
  },
  V: {
    offsets: {
      top: 0,
      left: 0,
    }
  }
}
