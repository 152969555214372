import FlashLib from "flashlib_onlyplay";
import MoneyFormat from "Engine/base/moneyFormat/MoneyFormat";

export default class BaseBetContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.incativeTintColor = 0xCCCCCC;

        this.valueText = null;
        this.currentValue = 0;

        this._showCurrency = false;

        this.init();
    }

    get showCurrency() {
        return this._showCurrency;
    }

    set showCurrency(value) {
        this._showCurrency = value;
    }

    init() {
        this.titleText = this.getChildByName('ui_bet_label');
        this.valueText = this.getChildByName('ui_bet_value');
    }

    setValue(value) {
        this.currentValue = value;
        this.valueText.text = MoneyFormat.format(this.currentValue, this.showCurrency, false);
        this.valueText.fitSize();
        this.valueText.correctPosition('center', 'top');
    }
}
