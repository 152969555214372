export default {
  ESI_WILD: 1,
  ESI_ORANGE_CAT: 2,
  ESI_PINK_CAT: 3,
  ESI_BLUE_CAT: 4,
  ESI_GREEN_CAT: 5,
  ESI_A: 6,
  ESI_K: 7,
  ESI_Q: 8,
  ESI_J: 9,
  ESI_10: 10,
  ESI_SCATTER: 11,
  ESI_BIG_WILD: 12,//temp
}
