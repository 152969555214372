import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../../enums/eEventTypes';
import ControllerExpandedWilds from './ControllerExpandedWilds';
import eStateTypes from '../../../enums/eStateTypes';
import EntryPoint from 'Engine/EntryPoint';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../../sounds/eSoundType';

export default class ControllerFeatures extends PIXI.Container {
  constructor(ControllerReels) {
    super();
    this.ControllerReels = ControllerReels;
    this.init()
    this.addListeners()
  }
  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_FEATURE_CHOICE__COMPLETED, this.expandWild, this);
    GlobalDispatcher.add(eEventTypes.EET_STATE_MANAGER__STATE_CHANGED, this.onStateChange, this, 0)
  }

  expandWild({ params: { reelId, isLastChoice } }) {
    SoundManager.play(eSoundType.EST_SHOW_BIG_WILD, 0.5, false);
    this.ControllerExpandedWilds.expand(reelId)
      .then(() => isLastChoice && GlobalDispatcher.dispatch(eEventTypes.EET_FEATURES__COMPLETE));
  }

  onStateChange({params: state}) {
    if (state === eStateTypes.EST_REQUEST_ROLLING) {
      let excludedReels = [];
      if (EntryPoint.GameModel.isFreeSpinsMode && !EntryPoint.GameModel.freespinsTriggered) {
        excludedReels = EntryPoint.GameModel.featureList.map(feature => feature.reelIndex)
      }
      this.ControllerReels.excludedReels = excludedReels;
      this.ControllerExpandedWilds.stop();
    }
  }

  init() {
      this.ControllerExpandedWilds = new ControllerExpandedWilds(this.ControllerReels);
      EntryPoint.GameModel.featureList.forEach((feature) => this.ControllerExpandedWilds.expand(feature.reelIndex))
  }
}
