import eSymbolIndex from '../../symbols/eSymbolIndex';

export default class ControllerExpandedWilds extends PIXI.Container {
  constructor(ControllerReels) {
    super();
    this.ControllerReels = ControllerReels;
    this._savedSymbols = []
    this.ControllerReels.reels.forEach(() => this._savedSymbols.push([]))
  }

  expand(reelId) {
    return new Promise(resolve => {
      const reel = this.ControllerReels.reels[reelId]
      const wildSymbol = reel.symbols[this.ControllerReels.topSimbolsCount]
      reel.setChildIndex(wildSymbol, reel.symbols.length - 1);
      this._savedSymbols[reelId] = [ ...this.ControllerReels.reels[reelId].symbols ];
      this.ControllerReels.reels[reelId].symbols = (new Array(this._savedSymbols[reelId].length)).fill(wildSymbol);
      wildSymbol.startExpandingAnimation().then(() => {
        wildSymbol.changeSymbol(eSymbolIndex.ESI_BIG_WILD);
        resolve();
      })
    })
  }

  stop() {
    this.ControllerReels.reels.forEach((reel, reelId) => {
      if (this._savedSymbols[reelId].length && !this.ControllerReels.excludedReels.includes(reelId)){
        this.ControllerReels.reels[reelId].symbols = [ ...this._savedSymbols[reelId] ];
        this._savedSymbols[reelId] = [];
      }
    })
  }
}
