import FlashLib from 'flashlib_onlyplay';

export default class ControllerStartScreenRect extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    const Graphic = new PIXI.Graphics();
    Graphic.beginFill(0xfeeada)
    Graphic.drawRoundedRect(-this.displayData.width/2, -this.displayData.height/2, this.displayData.width, this.displayData.height, 10);
    Graphic.endFill();
    this.addChildAt(Graphic, 0);
  }
}
