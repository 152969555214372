import { orangeGradientFontStyle } from '../../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../../sounds/eSoundType';
import eEventTypes from '../../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import { gsap } from 'gsap/all';

export default class ControllerFreespinsStart extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init()
  }
  init() {
    super.init();
    this.youWinText = this.wrapper.getChildByName('freespins_popup_start_you_win');
    this.amount = this.wrapper.getChildByName('amount');
    this.freespinsText = this.wrapper.getChildByName('freespins_popup_start_freespin');
    this.setTextStile()
  }
  setTextStile() {
    this.youWinText.style = { ...this.youWinText.style, ...orangeGradientFontStyle }
    this.amount.style = { ...this.amount.style, ...orangeGradientFontStyle }
    this.freespinsText.style = { ...this.freespinsText.style, ...orangeGradientFontStyle }
  }

  onShow() {
    super.onShow();
    this.amount.text = EntryPoint.GameModel.freespins;
    setTimeout(() => {
        this.hide();
      }
      ,5000)
    SoundManager.play(eSoundType.EST_START_FREESPINS, 0.5,false, 'winning');
  }
  onHide() {
    super.onHide();
      const freespinsSound = SoundManager.getSound(eSoundType.EST_START_FREESPINS);
      const tween  = gsap.timeline()
      tween
        .to(freespinsSound, { volume: 0, duration: 0.5})
        .call(() => {
          SoundManager.pause(eSoundType.EST_START_FREESPINS)
        })
    GlobalDispatcher.dispatch(eEventTypes.EET_FREESPINS_START__ON_COMPLETE)
  }
}
