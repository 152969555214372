import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap/all';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../../sounds/eSoundType';

export default class BasePopup extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.visible = false;
    this.addListeners();
  }
  init() {
    // this.bgAnimation = this.getChildByName('bgAnimation');
    this.background = this.getChildByName('background');
    this.wrapper = this.getChildByName('wrapper');
  }
  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (data.isMobile && data.isPortrait) {
      this.wrapper.centring();
      this.wrapper.scale.set(0.86);
      this.background.goToFrame(1);
    } else {
      this.wrapper.scale.set(this.wrapper.displayData.scaleX);
      this.wrapper.anchorDefault();
      this.background.goToFrame(2);
    }
  }

  show(delay) {
    setTimeout(() => {
      this.visible = true;
      gsap.to(this,{ alpha: 1, duration: 0.6 })
    }, delay)
    this.stopMainBackgroundSound();
    this.onShow();
    // this.bgAnimation.start();
  }

  hide() {
    gsap.to(this,  { alpha: 0, duration: 0.3, onComplete: () => {
        this.visible = false;
        // this.bgAnimation.stop();
        this.onHide();
      }
    })
    this.playMainBackgroundSound();
  }

  onShow() {

  }

  onHide() {

  }

  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline()
    tween
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5})
      .call(() => {
        SoundManager.pause(eSoundType.EST_BACKGROUND)
      })
  }
  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline();
    tween
      .call(() => {
        SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
      })
      .fromTo(backgroundMainGameSound, {volume: 0}, {volume: 0.5, duration: 1.5})
  }
}
