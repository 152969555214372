import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import FlashLib from 'flashlib_onlyplay';
import eAnimationTypes from '../../enums/eAnimationTypes';
import animationCreator from 'Engine/animations/animationCreator';
import { eSymbolsStopAnimationNames, eSymbolsAnimationNames } from '../../enums/eAnimationNames';

export default class SymbolAnimation extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.onComplete = () => {};

    this.animationListener = {
      complete: (data) => {
        this._complete(data)
      }
    };

    this.animation = null;
    this.init()
    this.addListeners();
  }

  init() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_SYMBOLS);
    this.animation.visible = false;
    this.animation.state.addListener(this.animationListener);
    this.addChild(this.animation);
  }

  addListeners() {
    GlobalDispatcher.add('stateStart:jackpotGameInState', ()=> this.isActive = this.animation.visible, this); //temp
    GlobalDispatcher.add('stateStart:jackpotGameOutState', () => this.animation.visible = this.isActive, this); //temp
  }

  start(loop) {
    this._playSpineAnimation(loop);
  }

  startExpandingAnimation() {
    this.animation.y = 249;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eSymbolsAnimationNames.ESAN_BIG_WILD_EXPAND, false);
    return new Promise((resolve) => this.resolve = resolve)
  }

  _playSpineAnimation(loop) {
    this.animation.y = 0;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eSymbolsAnimationNames[this.id], loop);
  }
  startStopAnimation() {
    this.animation.visible = true;
    this.animation.skeleton.setToSetupPose();
    this.animation.state.setAnimation(0, eSymbolsStopAnimationNames[this.id], false);
  }

  _complete(data) {
    if (data.animation.name === eSymbolsAnimationNames.ESAN_BIG_WILD_EXPAND && this.resolve) {
      this.resolve();
      this.resolve = null;
    }
    // this.animation.visible = false;
    this.onComplete(data);
    // this.emit('animationComplete', this);
  }

  stop() {
    if (this.animation) {
      this.animation.visible = false
    }
  }
}
