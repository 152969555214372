import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import ControllerFallingItem from './ControllerFallingItem';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationNames, { eCatThrowsItemAnimationNames } from '../../enums/eAnimationNames';
import EntryPoint from 'Engine/EntryPoint';
import eAnimationTypes from '../../enums/eAnimationTypes';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';

export default class ControllerFeatureChoice extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.addListeners();
    this._fallingItems = [];
    const itemCount = 5;
    for (let i = 0; i < itemCount; i++) {
        const fallingItem = this.getChildByName(`item_${i}`)
      fallingItem.init(i);
      this._fallingItems.push(fallingItem);
    }
    EntryPoint.GameModel.featureList.forEach((feature)=>this._fallingItems[feature.reelIndex].hide())
    this._initAnimation();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_FEATURES_STATE__START, this.choice, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (data.isMobile && data.isPortrait) {
      this.x = 515;
      this.y = 242;
      this.scale.set(0.558441);
    } else {
      this.scale.set(1);
      this.x = this.displayData.x;
      this.y = this.displayData.y;
    }
  }

  choice() {
    const featureList  = EntryPoint.GameModel.featureList;
    if (this._idleTrack) this._idleTrack.animationEnd = 0;
    this.catAnimation.state.addAnimation(0, eAnimationNames.EAN_CAT__IDLE_END, false, 0);
    featureList.forEach((feature, index) => {
      if(feature.isUsed) return;
      const isLastChoice = index === featureList.length - 1
      this._throwItem(feature.reelIndex, isLastChoice)
      if (isLastChoice) this._lastReel = feature.reelIndex;
    })
    this.catAnimation.state.addAnimation(0, eAnimationNames.EAN_CAT__IDLE_START, false, 0);
    this._addIdle();
  }

  _choiceAnimationComplete(reelId, isLastChoice) {
    GlobalDispatcher.dispatch(eEventTypes.EET_FEATURE_CHOICE__COMPLETED, { reelId, isLastChoice });
  }

  _initAnimation() {
    this.catAnimationContainer = this.getChildByName('catAnimationContainer');
    this.catAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_CAT)
    this.catAnimationContainer.addChild(this.catAnimation);
    // this.catAnimation.stateData.defaultMix = 0.05;
    // Object.values(eCatThrowsItemAnimationNames).forEach(throwAnimation => {
    //   this.catAnimation.stateData.setMix(throwAnimation, eAnimationNames.EAN_CAT__TRANSITION, 0);
    //   this.catAnimation.stateData.setMix(throwAnimation, eAnimationNames.EAN_CAT__IDLE_START, 0);
    // })
    this.catAnimation.state.addListener({
      start: this._onStartThrowsAnimation.bind(this)
    });
    this._addIdle();
  }

  _onStartThrowsAnimation(data) {
    let throwItemAnimationIndex = Object.keys(eCatThrowsItemAnimationNames).find((key) => eCatThrowsItemAnimationNames[key] === data.animation.name);

    if (throwItemAnimationIndex >= 0) {
      this._fallingItems[throwItemAnimationIndex].hide();
      throwItemAnimationIndex = +throwItemAnimationIndex;
      let delay = 0;
      switch (throwItemAnimationIndex) {
        case 0:
          delay = 5533;
          break;
        case 1:
          delay = 4867;
          break;
        case 2:
          delay = 4233;
          break;
        case 3:
          delay = 3467;
          break;
        case 4:
          delay = 2500;
      }
      setTimeout(() => this._choiceAnimationComplete(throwItemAnimationIndex, throwItemAnimationIndex === this._lastReel), delay)
      setTimeout(() => SoundManager.play(eSoundType.EST_THROW_ITEM, 0.4), delay - 1100)
      setTimeout(() => SoundManager.play(eSoundType.EST_PORTAL, 0.4), data.animation.duration * 1000 - 1200);

    } else if (data.animation.name === eAnimationNames.EAN_CAT__TRANSITION || data.animation.name === eAnimationNames.EAN_CAT__IDLE_START) {
      SoundManager.play(eSoundType.EST_PORTAL, 0.4)
    }
  }


  _addIdle() {
    this._idleTrack = this.catAnimation.state.addAnimation(0, eAnimationNames.EAN_CAT__IDLE, true, 0);
  }

  _throwItem(itemIndex, isLastChoice) {
    this.catAnimation.state.addAnimation(0, eCatThrowsItemAnimationNames[itemIndex], false, 0);
    if (!isLastChoice) this.catAnimation.state.addAnimation(0, eAnimationNames.EAN_CAT__TRANSITION, false, 0);
  }
}
