import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SlotGameModel from 'Engine/slots/model/SlotGameModel';
import eBigWinDefaultCoefs from '../enums/eBigWinDefaultCoefs';
import eBigWinTypes from '../enums/eBigWinTypes';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import ServerManager from '../server/ServerManager';
import eEventTypes from '../enums/eEventTypes';
import StatesManager from 'Engine/base/states/StatesManager';
import eBonusPurchaseCards from '../enums/eBonusPurchaseCards';
import eStateTypes from '../enums/eStateTypes';

export default new class GameModel extends SlotGameModel {
  constructor() {
    super();
    this._freespins = 0;
    this._freespinsTotalWin = 0;
    this._freespinsTriggered = false;
    this._featureList = [];

    GlobalDispatcher.addOnce(eEventTypes.EET_DATA_UPDATED,() => {
      this._winRankCoef = {
        [eBigWinTypes.EBWT_BIG_WIN]:  OPWrapperService.config.minWinRankCoef ? OPWrapperService.config.minWinRankCoef : eBigWinDefaultCoefs.EBWC_BIG_WIN,
        [eBigWinTypes.EBWT_MEGA_WIN]: eBigWinDefaultCoefs.EBWC_MEGA_WIN,
        [eBigWinTypes.EBWT_EPIC_WIN]: eBigWinDefaultCoefs.EBWC_EPIC_WIN
    };
    }, this);
  }

  init () {
    super.init();
    ServerManager.onInit();
  }


  setNewData(data) {
    super.setNewData(data);

    if (data.freespinsData) {
      this._freespinsTotalWin = data.freespinsData.totalWin || 0;
      this._freespins = data.freespinsData.freespins;
      this._freespinsTriggered = data.freespinsData.freespinsTriggered;
    }
    this._isFreeSpinsMode = data.freespinsData && data.freespinsData.isFreespinsGame;

    if (data.hasOwnProperty('bonusCostMultiplier')) this.bonusCostMultiplier = data.bonusCostMultiplier;

    if (data.hasOwnProperty('featureData')) {
      this._featureList = data.featureData.list || [];
      this._isFeature = data.featureData.isFeature;
    }

    GlobalDispatcher.dispatch(eEventTypes.EET_DATA_UPDATED, data.action);
  }

  buyBonus({ params }) {
    if (params.price > this.balance) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    this.bonusPurchaseData = params;
    StatesManager.goToState(eStateTypes.EST_BUY_BONUS);
  }

  getBonusPurchasePrice(type) {
    return this.bet * this.bonusCostMultiplier;
  }

  isPurchaseCardAvailable(type) {
    switch (type) {
      case eBonusPurchaseCards.EBPT_FREE_SPINS.type:
        return true;
      default:
        return false;
    }
  }

  get isBigWin() {
    return this.totalWin >= this.bet * 15;
  }

  get paytableCurrentInfo() {
    this._paytableCurrentInfo = {}
    for (const index in this._paytableInfo) {
      this._paytableCurrentInfo[index] = this._paytableInfo[index].map(coef => {
        return `×${coef}`;
      })
    }
    this._paytableCurrentInfo['wildField'] = this._paytableInfo[1][2] * this.lines

    return this._paytableCurrentInfo
  }

  get freespins() {
    return this._freespins;
  }
  set freespins(value) {
    this._freespins = value;
  }
  get freespinsTotalWin() {
    return this._freespinsTotalWin;
  }
  set freespinsTotalWin(value) {
    this._freespinsTotalWin = value;
  }
  get isFreeSpinsMode() {
    return this._isFreeSpinsMode;
  }
  get freespinsEnabled() {
    return this._bonusGameEnabled;
  }
  set freespinsEnabled(value) {
    this._bonusGameEnabled = value;
    this._isSpinWithoutBalanceCheck = value;
  }
  get freespinsTriggered() {
    return this._freespinsTriggered;
  }
  get totalWin() {
    let win = 0
    if (this._winnings) {
      win += this.winnings.totalWin;
    }
    return win;
  }
  get winRankCoef () {
    return this._winRankCoef;
  }
  get bonusPurchaseData() {
    return this._bonusPurchaseData;
  }
  set bonusPurchaseData(value) {
    this._bonusPurchaseData = value;
  }

  get featureList() {
    return this._featureList;
  }
  get isFeature() {
    return this._isFeature;
  }
}
