export default {
  EAT_SYMBOLS: 'symbols',
  EAT_BIG_WIN: 'bigWin_spine',
  EAT_NEAR_WIN: 'neat_win',
  EAT_PURCHASE_BUTTON: 'purchase_button',
  EAT_PURCHASE: 'purchase',
  EAT_BACKGROUND: 'background',
  EAT_ANTISIPATOR: 'antisipator',
  EAT_CAT: 'cat',
}
