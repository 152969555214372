import FlashLib from 'flashlib_onlyplay';
import { orangeGradientFontStyle, shadowText } from '../../fontStyles';
import BaseMoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class TotalWinContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
  }
  init() {
    this.lableField = this.getChildByName('freespins_game_total_win');
    this.amountField = this.getChildByName('amount');
    this.setTextStyles();
  }
  setTextStyles() {
    this.lableField.style = { ...this.lableField.style, ...shadowText }
    this.amountField.style = { ...this.amountField.style, ...orangeGradientFontStyle }
  }
  setValue(value) {
    this.amountField.text = BaseMoneyFormat.format(value,true );
  }

}
