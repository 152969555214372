import BaseSettingsContainer from 'Engine/containers/setting/BaseSettingsContainer';
import { orangeGradientFontStyle } from '../../../fontStyles';

export default class ControllerSettings extends BaseSettingsContainer {
  init() {
    super.init();
    this.overlay = this.getChildByName('overlayContainer');
    this.overlay.changeColor(0x240913);
    this.title = this.wrapper.getChildByName('settings_sound_title');
    this.title.style = { ...this.title.style, ...orangeGradientFontStyle }
  }
}
