import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { orangeGradientFontStyle } from '../../fontStyles';
import eEventTypes from '../../enums/eEventTypes';

export default class PaytableContent extends FlashLib.MovieClip{
  constructor(data, displayData) {
    super(data, displayData);

    this.currentFrame = 1;
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE__CHANGE_PAGE, this.changeFrame, this);
  }

  setMultipliersStyle() {
    switch (this.currentFrame) {
      case 4:
        for (let i = 0; i <= 4; i++) {
          const coefs = this.children[1].getChildByName(`paytable_p4_coefs${i}`)
          const counts = this.children[1].getChildByName(`paytable_p4_count${i}`)
          if (counts) {
            counts.style = { ...counts.style, ...orangeGradientFontStyle}
          }
          if (coefs) {
            coefs.style = { ...coefs.style, ...orangeGradientFontStyle}
          }
        }
        break;
      case 5:
        for (let i = 0; i <= 5; i++) {
          const coefs = this.children[1].getChildByName(`paytable_p5_coefs${i}`)
          const counts = this.children[1].getChildByName(`paytable_p5_count${i}`)
          if (counts) {
            counts.style = { ...counts.style, ...orangeGradientFontStyle}
          }
          if (coefs) {
            coefs.style = { ...coefs.style, ...orangeGradientFontStyle}
          }
        }
        break;
      case 2:
        for (let i = 0; i < 3; i++) {
          const coefs = this.children[1].getChildByName(`paytable_p2_coefs${i}`)
          const counts = this.children[1].getChildByName(`paytable_p2_count${i}`)
          if (counts) {
            counts.style = { ...counts.style, ...orangeGradientFontStyle}
          }
          if (coefs) {
            coefs.style = { ...coefs.style, ...orangeGradientFontStyle}
          }
        }
        break;
    }
  }

  setMultipliers() {
    switch (this.currentFrame) {
      case 4:
        for (let i = 2; i <= 5; i++) {
          let element = this.children[1].getChildByName(`paytable_p4_coefs${i-2}`);
          if (element) {
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[i]];
            amounts = amounts.slice(2, amounts.length);
            // amounts = amounts.map(amount => MoneyFormat.formatCurrency(amount, EntryPoint.GameModel.currencyInfo.decimals))
            element.text = amounts.join('\n');
          }
        }
          // const wildFieldCoef = this.children[1].getChildByName('paytable_wild_coef');
          // wildFieldCoef.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.paytableCurrentInfo['wildField'], EntryPoint.GameModel.currencyInfo.decimals);
          // const wildFieldPlusChiliMaxCoef = this.children[1].getChildByName('paytable_chili_coef');
          // wildFieldPlusChiliMaxCoef.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.paytableCurrentInfo['wildFieldPlusChili'], EntryPoint.GameModel.currencyInfo.decimals);
        break;
      case 5:
        for (let i = 6; i <= 10; i++) {
          let element = this.children[1].getChildByName(`paytable_p5_coefs${i-6}`);
          if (element) {
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[i]];
            amounts = amounts.slice(2, amounts.length);
            // amounts = amounts.map(amount => MoneyFormat.formatCurrency(amount, EntryPoint.GameModel.currencyInfo.decimals))
            element.text = amounts.join('\n');
          }
        }
          // const wildFieldCoef = this.children[1].getChildByName('paytable_wild_coef');
          // wildFieldCoef.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.paytableCurrentInfo['wildField'], EntryPoint.GameModel.currencyInfo.decimals);
          // const wildFieldPlusChiliMaxCoef = this.children[1].getChildByName('paytable_chili_coef');
          // wildFieldPlusChiliMaxCoef.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.paytableCurrentInfo['wildFieldPlusChili'], EntryPoint.GameModel.currencyInfo.decimals);
        break;
      case 2:
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[1]];
            amounts = amounts.slice(2, amounts.length);
            // amounts = amounts.map(amount => MoneyFormat.formatCurrency(amount, EntryPoint.GameModel.currencyInfo.decimals))

        for (let i = 0; i < 3; i++) {
          let element = this.children[1].getChildByName(`paytable_p2_coefs${i}`);
          if (element) {
            element.text = `${amounts[i]}`
          }
        }
        break;
    }
  }

  setTextsStyle() {
    for (let i = 1; i <= 6; i++) {
      const label = this.children[1].getChildByName(`paytable_p${this.currentFrame}_label`)
      if (label) {
        label.style = { ...label.style, ...orangeGradientFontStyle}
      }
    }
  }

  changeFrame(event) {
    this.currentFrame = event.params || 1;
    this.goToFrame(this.currentFrame);
    this.setMultipliersStyle();
    this.setMultipliers()
    this.setTextsStyle()
  }

  destroy(){
    GlobalDispatcher.remove(eEventTypes.EET_PAYTABLE__CHANGE_PAGE, this.changeFrame, this);
    super.destroy();
  }
}
