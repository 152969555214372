import * as PIXI from 'pixi.js';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eSoundType from '../../sounds/eSoundType';
import FlashLib from 'flashlib_onlyplay';
import eSymbolIndex from '../symbols/eSymbolIndex';
import eEventTypes from '../../enums/eEventTypes';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';

export default class ReelsContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
    this.addListeners();
    this.initControllerReels();
  }

  init() {
    this._stopSoundPlayed = false;
    this._scatterSoundPlayed = false;
    this.hasScatter = false;
    this._scatterSymbols = [];

    this.ControllerReels = this.getChildByName('controllerReels');
    this.Antisipators = this.getChildByName('antisipators')
    this.Antisipators.mainSoundType = eSoundType.EST_REEL_ANTISIPATOR
    this.Antisipators.tileSoundType = eSoundType.EST_REEL_ANTISIPATOR_TILE
    this.Antisipators.animationType = eAnimationTypes.EAT_ANTISIPATOR
    this.Antisipators.animationName = eAnimationNames.EAN_ANTISIPATOR
    this.Antisipators.triggerSymbolsId = eSymbolIndex.ESI_SCATTER;
    this.Antisipators.init();

    this._drawMask();
  }

  initControllerReels() {
    this.ControllerReels.hideBottomSymbols = true;
    this.ControllerReels.init(EntryPoint.GameModel.isFreeSpinsMode
      ? EntryPoint.configData.reels.availableFreespinsReels
      : EntryPoint.configData.reels.availableReelsNormal
    );
  }

  addListeners() {
    this.ControllerReels.onHittingBar = this.onHitBar.bind(this);
    this.ControllerReels.onReelStopped = this.onReelStopped.bind(this);
    this.ControllerReels.onAllReelsStopped = this.onAllReelsStopped.bind(this);
    GlobalDispatcher.add(eEventTypes.EET_WINNINGS_CONTROLLER__SLOW_WIN, this.onSlowWin, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPIN_IN_STATE__START, this.onStartBonusGameInState, this);
    GlobalDispatcher.add(eEventTypes.EET_FREESPIN_OUT_STATE__START,this.onStartBonusGameOutState, this);
  }

  onHitBar(reelIndex) {
    this.Antisipators.tryStopAntisipatorOnReel(reelIndex);
    this.Antisipators.tryStartAntisipatorForReel(reelIndex + 1);

    if(!EntryPoint.GameModel.freespinsEnabled) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      for (let i = this.ControllerReels.topSimbolsCount; i <= symbols.length - 1 - this.ControllerReels.bottomSimbolsCount; i++) {
        const symbol = symbols[i];
          if (symbol.id === eSymbolIndex.ESI_SCATTER) {
            this.hasScatter = true;
            this._scatterSymbols.push(symbol);
          }
        }
    }

   this.playStopSound();
  }

  playStopSound() {
    if (!this._stopSoundPlayed) {
      SoundManager.play(eSoundType.EST_REEL_STOP, 0.5 + 0.2 * Math.random());
      this._stopSoundPlayed = EntryPoint.GameSettings.quickStop;
    }

    if (this.hasScatter) {
      this.hasScatter = false;
      if (!this._scatterSoundPlayed) {
        SoundManager.play(eSoundType.EST_SCATTER_STOP, 0.3 + 0.2 * Math.random());
        this._scatterSoundPlayed = EntryPoint.GameSettings.quickStop;
      }
    }
  }

  onReelStopped(reelIndex) {
    const spacialSymbolIndex = EntryPoint.GameModel.winnings?.jackpotters?.length && EntryPoint.GameModel.winnings?.jackpotters[0]?.path[reelIndex];
    if (spacialSymbolIndex) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      symbols[spacialSymbolIndex].startStopAnimation();
    }
  }

  onAllReelsStopped() {
    GlobalDispatcher.dispatch(eEventTypes.EET_REELS_STOPPED);
  }

  onSlowWin(data) {
    this._showWinningSymbols(data);
  }

  onStartBonusGameInState() {
    this._scatterSymbols.forEach(scatterSymbol => scatterSymbol.startAnimation());
    this._setFreespinsReels()
  }

  onStartBonusGameOutState() {
    this._setMainGameReels()
  }

  start() {
    this._stopSoundPlayed = false;
    this._scatterSoundPlayed = false;
    this._scatterSymbols = [];
    this.ControllerReels.start();
  }

  stop(data) {
    this.data = data;
    if (EntryPoint.GameSettings.quickStop || EntryPoint.GameSettings.quickSpin) {
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.impactCoefficient = 0.25;
      this.ControllerReels.symbolsCountForStop = 3;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 1]
    } else {
      this.Antisipators.checkAntisipators(this.data)
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.symbolsCountForStop = this._getSymbolsCountForStop();
      this.ControllerReels.impactCoefficient = 0.2;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 0.5]
    }
    this.ControllerReels.stop(this.data);
  }

  _getSymbolsCountForStop() {
    //[3, 8, 13, 18, 23]
    const firstReelSymbolCount = 3;
    const different = 5;
    const antisipatorDifferent = 40;
    const reelCount = EntryPoint.configData.reels.reelsCount

    const symbolsCountForStop = [firstReelSymbolCount];
    let lastCount = firstReelSymbolCount;

    for (let reelId = 1; reelId < reelCount; reelId++) {
      let symbolsCount =  lastCount + different
      if (this.Antisipators.involvedReels.includes(reelId)) {
        symbolsCount = symbolsCount + antisipatorDifferent
      }
      symbolsCountForStop.push(symbolsCount);
      lastCount = symbolsCount
    }
    return symbolsCountForStop;
  }

  _setFreespinsReels() {
    this.ControllerReels.setAvailableSymbols(EntryPoint.configData.reels.availableFreespinsReels);
  }

  _setMainGameReels() {
    this.ControllerReels.setAvailableSymbols(EntryPoint.configData.reels.availableReelsNormal);
  }

  _showWinningSymbols(data) {
    // this.hideWinningSymbols();
    const winLineIds = [];
    data.params.path.forEach((pathData, index) => {
      if (pathData >= 0) {
        const symbol = this.ControllerReels.reels[index].symbols[pathData];
        symbol.startAnimation();
        winLineIds.push(symbol.id);
      }
    });
    if (!data.params.isScatter) GlobalDispatcher.dispatch(eEventTypes.EBET_SET_WIN_LINE_TO_UI, { winLineIds, winAmount: data.params.win, winLineCoef: data.params.coef})

  }

  _drawMask() {
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRect(-100,17,1700,658);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
  }
}
