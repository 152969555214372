import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import { getRandom } from 'Engine/utils/getRandom';
import eStateTypes from '../../enums/eStateTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import FlashLib from 'flashlib_onlyplay';
import StatesManager from '../../libs/game-engine/src/base/states/StatesManager';

export default class BaseGameUiContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
    this.addListeners();
    this.addActions();
  }

  init() {
    this.purchaseButton = this.getChildByName('purchaseButton');
    makeHitArea(this.purchaseButton);
    this.purchaseButton.enabled = !EntryPoint.GameModel.isFreeSpinsMode;
    this.purchaseButton.visible = !EntryPoint.GameModel.isFreeSpinsMode;
    if (window.OPWrapperService.config.disableBuyBonus) this.removeChild(this.purchaseButton);
  }

  addListeners() {
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data, priorityFrameIndex) {
    this.stopPurchaseAnimation();
    const frameIndex = priorityFrameIndex || (data.isLandscape ? 2 : 1);
    if (frameIndex === this.currentFrameIndex) return;
    this.goToFrame(frameIndex);
    this.init();
    this.addActions();
    this.onStateChanged({ params: StatesManager.currentState.stateData.name });
  }

  initPurchaseAnimation() {
    this.purchaseButtonAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_PURCHASE_BUTTON);
    this.purchaseButtonAnimation.state.addListener({
      complete: this.loopPurchaseAnimation.bind(this)
    });
    this.purchaseButton.animation = this.purchaseButtonAnimation;
  }

  loopPurchaseAnimation() {
    if (!this.purchaseButtonAnimation) {
      this.initPurchaseAnimation();
    }

    this.purchaseButtonAnimation.visible = false;
    let delay = getRandom(2000, 10000);

    this.purchaseAnimationTimeout = setTimeout(() => {
      if (!this.purchaseButtonAnimation) return;

      this.purchaseButtonAnimation.visible = true;
      this.purchaseButtonAnimation.state.setAnimation(0, eAnimationNames.EAN_PURCHASE__OPEN_POPUP_BUTTON, false);
    }, delay);
  }

  stopPurchaseAnimation() {
    if (this.purchaseAnimationTimeout) {
      clearTimeout(this.purchaseAnimationTimeout);
      this.purchaseAnimationTimeout = null;
    }

    if (this.purchaseButtonAnimation) {
      this.purchaseButtonAnimation.destroy();
      this.purchaseButtonAnimation = null;
    }
  }

  addActions() {
    this.purchaseButton.on('pointertap', this.onPurchaseClick, this);
  }

  onPurchaseClick() {
    GlobalDispatcher.dispatch(eEventTypes.EET_PURCHASE_BTN_CLICK);
  }

  onStateChanged(event) {
    switch (event.params) {
      case eStateTypes.EST_IDLE:
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
          this.purchaseButton.enabled = true;
          this.purchaseButton.visible = true;
          this.loopPurchaseAnimation();
        }
        break;

      case eStateTypes.EST_REQUEST_ROLLING:
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;

      case eStateTypes.EST_FREE_SPINS_IN:
        this.stopPurchaseAnimation();
        this.purchaseButton.visible = false;
        break;

      default:
        this.purchaseButton.enabled = false;
        break;
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.stopPurchaseAnimation();
      this.purchaseButton.enabled = false;
      this.purchaseButton.visible = false;
    }
  }
}
