import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eAnimationNames from '../../enums/eAnimationNames';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerFallingItem extends FlashLib.MovieClip {
  init(index) {
    this.index = index;
    this.goToFrame(index + 1)
    this.image = this.getChildAt(0);
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_CAT);
    this.animation.visible = false;
    this.animationContainer = this.getChildByName('animationContainer');
    this.animationContainer.addChild(this.animation);
    this.addListeners()
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_ROLLING_STATE__START, this.tryShow, this);
  }

  hide() {
    this.image.visible = false;
  }

  show() {
    if (this.image.visible) return;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eAnimationNames.EAN_CAT__ITEM_APPEAR, false);
    setTimeout(()=>     this.image.visible = true, 20)
  }

  tryShow() {
    if(!EntryPoint.GameModel.isFreeSpinsMode || EntryPoint.GameModel.freespinsTriggered) this.show();
  }
}
