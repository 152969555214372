import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import WinAmountAnimated from '../../../winAmountAnimated/winAmountAnimated';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import StatesManager from 'Engine/base/states/StatesManager';
import eSoundType from '../../../sounds/eSoundType';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../../enums/eAnimationTypes';
import { gsap } from 'gsap';
import SoundManager from 'Engine/soundManager/SoundManager';
import eBigWinTypes from '../../../enums/eBigWinTypes';
import eEventTypes from '../../../enums/eEventTypes';
import { eBigWinAnimationNames, eEpicWinAnimationNames, eMegaWinAnimationNames } from '../../../enums/eAnimationNames';
import GameModel from '../../../model/GameModel';

export default class PopupWin extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.eventMode = 'static';

    this.stepsData = {
      [eBigWinTypes.EBWT_BIG_WIN]:  { delayed: true, animationName: eBigWinAnimationNames, sound: eSoundType.EST_BIG_WIN },
      [eBigWinTypes.EBWT_MEGA_WIN]: { delayed: true, animationName: eMegaWinAnimationNames, sound: eSoundType.EST_MEGA_WIN},
      [eBigWinTypes.EBWT_EPIC_WIN]: { delayed: true, animationName: eEpicWinAnimationNames, sound: eSoundType.EST_EPIC_WIN},
    }

    this.stepsSequence = [
      eBigWinTypes.EBWT_BIG_WIN,
      eBigWinTypes.EBWT_MEGA_WIN,
      eBigWinTypes.EBWT_EPIC_WIN,
    ]

    this.animationCycleTime = 2000;
    this.animationStack = [];
    this.animationListener = {
      complete: ({ animation: { name } }) => {
        if (/start/.test(name)) this.executeAnimation();
      }
    };

    this.decimals = GameModel.currencyInfo.decimals;

    this.addListeners();
    this.init();
    this._initAnimation();
  }

  init() {
    this.visible = false;
    this.overlay = this.getChildByName('overlay');
    this.overlay.changeColor(0x240913);
    this.wrapper = this.getChildByName('wrapper');
    this.panel = this.wrapper.getChildByName('panel');
    this._hideBigWinBackground();
    this.smallWinContainer = this.wrapper.getChildByName('smallPanel');
    this.smallWinContainer.visible = false;
    this.smallWinAmount = this.smallWinContainer.getChildByName('amount');
    this.smallWinCounter = new WinAmountAnimated(this.smallWinAmount, { fontSize: this.decimals >= 8 ? 158 : 239 })
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__END, this.complete, this);
    GlobalDispatcher.add(eEventTypes.EET_SHOW_WIN_STATE__START, this.show, this);
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__START, this.stop, this);
    if (!window.OPWrapperService.config.skipBlocked) this.on('pointertap', () => StatesManager.goToNextState());
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (data.isMobile && data.isPortrait) {
      this.wrapper.centring();
      this.wrapper.y = this.displayData.height / 2  - 630 - 100;
      this.wrapper.x = this.displayData.width / 2;
      this.wrapper.scale.set(0.6);
    } else {
      this.wrapper.anchorDefault();
      this.wrapper.scale.set(this.wrapper.displayData.scaleX)
    }
  }

  async _initAnimation() {
    this.animation = await animationCreator.createAnimationWithDelayedLoading(eAnimationTypes.EAT_BIG_WIN);
    if (!this.animation) return;
    this.animation.visible = false;
    this.animation.state.addListener(this.animationListener);
    this.wrapper.getChildByName('animationContainer').addChild(this.animation);
  }

  show() {
    this.visible = true;
    this.eventMode = 'static';
    this.alpha = 1;
    const firstDuration = 500;
    this.showBigWin(firstDuration);

  }

  stop() {
    if (this.animationStack.length) {
      this.animation.visible = true;
      const animationName = this.animationStack[this.animationStack.length - 1].name;
      this.animation.state.setAnimation(0, animationName, true);
      this._showBigWinBackground()
      this.animationStack = [];
    }
    this.executeAnimation()
    this.smallWinCounter.onStop = () => this.smallWinCounter.label.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.totalWin, this.decimals, false);
    this.smallWinCounter.stop();
  }

  complete() {
    this.hide();
  }

  hide() {
    this.eventMode = 'auto';
    SoundManager.stop(eSoundType.EST_BIG_WINS_BACKGROUND)
    this.playMainBackgroundSound();
    gsap.to(this, { alpha: 0, duration: 0.4, onComplete: () => {
        this.visible = false;
        this.animation.visible = false;
        this.smallWinContainer.visible = false;
        this.animationStack = [];
        this._hideBigWinBackground()
      }})
  }

  async showBigWin(duration) {
    if (!PIXI.Assets.cache.has(eAnimationTypes.EAT_BIG_WIN)) {
      window.OPPreloader.showDelayedPreloader();
      await PIXI.Assets.load(eAnimationTypes.EAT_BIG_WIN);
      window.OPPreloader.hideDelayedPreloader();
    }
    this.stopMainBackgroundSound()

    this.winDataIndex = this.stepsSequence.findIndex((bigWinType) => {
      return EntryPoint.GameModel.winRankCoef[bigWinType] > EntryPoint.GameModel.totalWin / EntryPoint.GameModel.bet;
    });

    if (this.winDataIndex === -1) {
      this.winDataIndex = this.stepsSequence.length - 1;
    } else {
      this.winDataIndex = this.winDataIndex - 1;
    }

    duration += (this.winDataIndex + 1) * 3800;

    this.createAnimationStack(this.winDataIndex);

    this.startAnimatedValue = 0;
    this.nextAnimatedValue = this.animationStack.length ? EntryPoint.GameModel.totalWin / (this.animationStack.length + 2) * 2 : EntryPoint.GameModel.totalWin;
    this.animationCycleTime = duration / (this.animationStack.length + 2) * 2 ;

    this.smallWinContainer.visible = true;
    this.smallWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime, 500);
    this.smallWinCounter.onStop = this.showBigWinAnimation.bind(this);

    SoundManager.play(eSoundType.EST_BIG_WINS_BACKGROUND, 0.4, true, 'winning')
  }

  showBigWinAnimation() {
    this.smallWinCounter.onStop = this.executeAnimation.bind(this);
    this.startAnimatedValue = this.nextAnimatedValue;
    this.nextAnimatedValue = EntryPoint.GameModel.totalWin / (this.animationStack.length ) * 2;
    this.executeAnimation();
  }

  createAnimationStack(index) {
    this.stepsSequence.forEach((bigWinType, i) => {
      if (i <= index) {
        const el = this.stepsData[bigWinType];
        const start = { name: el.animationName.START, type: 'start', sound: el.sound, coef: EntryPoint.GameModel.winRankCoef[bigWinType] };
        const idle = { name: el.animationName.IDLE, type: 'idle', sound: el.sound, coef: EntryPoint.GameModel.winRankCoef[bigWinType] };

        this.animationStack = [...this.animationStack, start, idle];
      }
    });
  }

  executeAnimation() {
    if (!this.animationStack.length) {
      GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP);
      return setTimeout(() => GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP_COMPLETED), 1500);
    }

    const animation = this.animationStack.shift();
    let loop = false;

    if (animation.type === 'start') {
      this._showBigWinBackground();
      this.animateWinning();
      SoundManager.play(animation.sound, 0.5, false, 'winning')
    }

    if (animation.type === 'idle') {
      loop = true;
      this.startAnimatedValue = this.nextAnimatedValue;
      this.nextAnimatedValue = EntryPoint.GameModel.totalWin / this.animationStack.length * 2;
    }
    this.animation.visible = true;
    this.animation.state.setAnimation(0 ,animation.name, loop);
  }

  animateWinning() {
      this.smallWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime, 500);
  }


  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline()
    tween
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5})
      // .call(() => {
      //   SoundManager.pause(eSoundType.EST_BACKGROUND)
      // })
  }
  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline();
    tween
      // .call(() => {
      //   SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
      // })
      .fromTo(backgroundMainGameSound, {volume: 0}, {volume: 0.5, duration: 1.5})
  }

  _showBigWinBackground() {
    const duration = 0.6;
    this.overlay.show(duration);
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
    gsap.killTweensOf(this.panel);
    gsap.to(this.panel, {alpha: 0, duration});
  }

  _hideBigWinBackground() {
    this.overlay.hide();
    gsap.killTweensOf(this.panel);
    this.panel.alpha = 1;
  }
}

