import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eStateTypes from '../../enums/eStateTypes';
import eEventTypes from '../../enums/eEventTypes';

export default class ControllerBringToFrontSymbols extends PIXI.Container {
  constructor() {
    super();
    this.symbols = [];
    this.parents = [];
    this.addListeners()
  }
  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_SPACIAL_SYMBOL_HIT_THE_BAR, this.onHitSymbolTheBar, this);
    GlobalDispatcher.add(eEventTypes.EET_STATE_MANAGER__STATE_CHANGED, this.onStateChanged, this);
  }

  onHitSymbolTheBar({params: symbol}) {
    this._bringSymbolToFront(symbol)
  }

  onStateChanged({params: stateName}) {
    if (stateName === eStateTypes.EST_REQUEST_ROLLING) {
      this._bringSymbolsBack();
    }
  }

  _bringSymbolToFront(symbol) {
    this.parents.push(symbol.parent);
    const animationSymbolPosition = this.toLocal({ x: 0 , y: 0 }, symbol);
    symbol.x = animationSymbolPosition.x;
    symbol.y = animationSymbolPosition.y;
    this.addChild(symbol);
    this.symbols.push(symbol);
  }

  _bringSymbolsBack() {
    this.symbols.forEach((symbol, index) =>{
      const animationSymbolPosition = this.parents[index].toLocal({ x: 0 , y: 0 }, symbol);
      symbol.x = animationSymbolPosition.x;
      symbol.y = animationSymbolPosition.y;
      this.parents[index].addChild(symbol);
    })
    this.symbols = [];
    this.parents = [];
  }
}
