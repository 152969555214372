import eSymbolIndex from '../controllers/symbols/eSymbolIndex';

export default {
  EAN_BACKGROUND__LANDSCAPE: 'fireplace',
  EAN_BACKGROUND__PORTRAIT: 'bg_coins_joker_x_mas_mob',
  EAN_POPUP_BACKGROUND: 'sample_pop-up',

  EAN_PURCHASE__TITLE__START: 'title_start',
  EAN_PURCHASE__TITLE__IDLE: 'title_idle',
  EAN_PURCHASE__BUY_BUTTON: 'buy_button_side',
  EAN_PURCHASE__OPEN_POPUP_BUTTON: 'animation',
  EAN_PURCHASE__CARD_FREESPINS__IDLE: 'idle_free_spins_card',
  EAN_PURCHASE__CARD_FREESPINS__ACTION: 'buy_free_spins_card',
  EAN_CAT__IDLE: 'idle',
  EAN_CAT__IDLE_END: 'idle_out',
  EAN_CAT__IDLE_START: 'intro',
  EAN_CAT__ITEM_APPEAR: 'obj_appear',
  EAN_CAT__TRANSITION: 'transition',

  EAN_ANTISIPATOR: 'animation',
}

export const eSymbolsAnimationNames = {
  [eSymbolIndex.ESI_WILD]: 'cat_6',
  [eSymbolIndex.ESI_PINK_CAT]: 'cat_3',
  [eSymbolIndex.ESI_ORANGE_CAT]: 'cat_1',
  [eSymbolIndex.ESI_BLUE_CAT]: 'cat_2',
  [eSymbolIndex.ESI_GREEN_CAT]: 'cat_5',
  [eSymbolIndex.ESI_A]: 'a',
  [eSymbolIndex.ESI_K]: 'k',
  [eSymbolIndex.ESI_Q]: 'q',
  [eSymbolIndex.ESI_J]: 'j',
  [eSymbolIndex.ESI_10]: '10',
  [eSymbolIndex.ESI_SCATTER]:'cat_4',
  [eSymbolIndex.ESI_BIG_WILD]: 'wild_big_clean',
  ESAN_BIG_WILD_EXPAND: 'wild_expand_clean'
}

export const eSymbolsStopAnimationNames = {
  [eSymbolIndex.ESI_J]: 'arlekin_stop',
  [eSymbolIndex.ESI_10]: 'coin_digit_stop',
  [eSymbolIndex.ESI_SCATTER]: 'arlekin_stop',
  [eSymbolIndex.ESI_BIG_WILD]: 'coin_crown_stop'
}

export const eBigWinAnimationNames = {
  START: 'big_win_start',
  IDLE: 'big_win_idle'
}
export const eMegaWinAnimationNames = {
  START: 'mega_win_start',
  IDLE: 'mega_win_idle'
}
export const eEpicWinAnimationNames = {
  START: 'epic_win_start',
  IDLE: 'epic_win_idle'
}

export const eCatThrowsItemAnimationNames = {
  0: 'obj_1',
  1: 'obj_2',
  2: 'obj_3',
  3: 'obj_4',
  4: 'obj_5',
}
