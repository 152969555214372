export  default  {
  EST_BACKGROUND: 'SFX',
  EST_REEL_ANTISIPATOR: 'antisipatorStart',
  EST_REEL_ANTISIPATOR_TILE: 'antisipatorEnd',
  EST_BIG_WIN: 'bigWin',
  EST_MEGA_WIN: 'megaWin',
  EST_EPIC_WIN: 'epicWin',
  EST_BIG_WINS_BACKGROUND: 'bigWinBG',
  EST_BUTTON_CLICK: 'buttonClick',
  EST_REEL_STOP: 'reelStop',
  EST_SCATTER_STOP: 'scatterStop',
  EST_SHOW_BIG_WILD: 'showBigWild',
  EST_FS_TOTAL_WIN: 'totalWin',
  EST_START_FREESPINS: 'winFreespins',
  EST_THROW_ITEM: 'throwItem',
  EST_PORTAL: 'portal',
}
