import FlashLib from 'flashlib_onlyplay';
import ControllerBringToFrontSymbols from './ControllerBringToFrontSymbols';
import ControllerFeatures from './features/ControllerFeatures';


export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init()
  }
  init() {
    this.reelsContainer = this.getChildByName('slotContainer').getChildByName('reelsContainer');

    this.ControllerReels = this.reelsContainer.ControllerReels;

    // this.ControllerBringToFrontSymbols = new ControllerBringToFrontSymbols();
    this.ControllerFeatures = new ControllerFeatures(this.ControllerReels);
    this.addChild(
      // this.ControllerBringToFrontSymbols,
      this.ControllerFeatures,
    );
  }
}
