import FlashLib from 'flashlib_onlyplay';
import BaseCheckBox from "Engine/base/ui/BaseCheckBox";

import Main from "./Main";

import ReelsContainer from "./controllers/reels/ReelsContainer";
import OverlayContainer from "Engine/containers/overlay/BaseOverlayContainer";

import Symbol from "./controllers/symbols/Symbol";

// import SlotLinesContainer from "./lines/SlotLinesContainer";
// import WinLineAnimated from "./lines/WinLineAnimated";


import StartScreenContainer from './controllers/startScreen/startScreen';

import UiContainer from "./controllers/ui/BaseGameUiContainer";
import BalanceContainer from "Engine/containers/ui/BaseBalanceContainer";
import WinContainer from "Engine/containers/ui/BaseWinContainer";
import MaxBetButton from "./controllers/ui/MaxBetButton";

import UiMobileContainer from "./controllers/ui/BaseGameUiContainer";

import InfoPopupContainer from "Engine/containers/InfoPopupContainer";


import PaytableContainer from "./controllers/paytable/PaytableContainer";
import PaytableContent from "./controllers/paytable/PaytableContent";
import PaytableControls from "./controllers/paytable/PaytableControls";
import SymbolAnimation from './controllers/symbols/SymbolAnimation';
import PopupWin from './controllers/popups/popupWin/PopupWin';
import BackgroundContainer from './controllers/background/BackgroundContainer';
import ControllerReels from 'Engine/base/reels/ControllerReels';
import TotalWinContainer from './controllers/ui/TotalWinContainer';
import ControllerFreespinsStart from './controllers/popups/freespins/ControllerFreespinsStart';
import ControllerFreespinsEnd from './controllers/popups/freespins/ControllerFreespinsEnd';
import ControllerSlot from './controllers/slot/ControllerSlot';
import ControllerPopupsBgAnimation from './controllers/background/ControllerPopupsBgAnimation';
import FreespinsContainer from './controllers/ui/FreespinsContainer';
import ControllerAntisipators from 'Engine/base/antisipators/ControllerAntisipators';
import ControllerBonusPurchase from './controllers/bonusPurchase/ControllerBonusPurchase';
import ControllerBonusPurchaseCard from './controllers/bonusPurchase/ControllerBonusPurchaseCard';
import ControllerConfirm from './controllers/bonusPurchase/ControllerConfirm';
import ButtonAnimated from './controllers/bonusPurchase/ButtonAnimated';
import ControllerFeatureChoice from './controllers/featureChoice/ControllerFeatureChoice';
import ControllerFallingItem from './controllers/featureChoice/ControllerFallingItem';
import ControllerStartScreenRect from './controllers/startScreen/ControllerStartScreenRect';
import ControllerSettings from './controllers/popups/settings/ControllerSettings';
import BetSelectorContainer from './controllers/ui/BetSelectorContainer';
import BaseGameButton from './controllers/ui/BaseGameButton';
import BetContainer from './controllers/ui/BetContainer';

FlashLib.registerClass('Button', BaseGameButton);
FlashLib.registerClass('CheckBox', BaseCheckBox);

FlashLib.registerClass('Main', Main);
FlashLib.registerClass('ReelsContainer', ReelsContainer);
FlashLib.registerClass('Symbol', Symbol);
// FlashLib.registerClass('WinLineAnimated', WinLineAnimated);
// FlashLib.registerClass('SlotLinesContainer', SlotLinesContainer);
FlashLib.registerClass('SettingsContainer', ControllerSettings);

FlashLib.registerClass('StartScreenContainer', StartScreenContainer);
FlashLib.registerClass('ControllerStartScreenRect', ControllerStartScreenRect);

FlashLib.registerClass('UiContainer', UiContainer);
FlashLib.registerClass('UiMobileContainer', UiMobileContainer);
FlashLib.registerClass('BalanceContainer', BalanceContainer);
FlashLib.registerClass('WinContainer', WinContainer);
FlashLib.registerClass('BetContainer', BetSelectorContainer);
FlashLib.registerClass('MobileBetContainer', BetContainer);
FlashLib.registerClass('MaxBetButton', MaxBetButton);
FlashLib.registerClass('InfoPopupContainer', InfoPopupContainer);
FlashLib.registerClass('OverlayContainer', OverlayContainer);
FlashLib.registerClass('PaytableContainer', PaytableContainer);
FlashLib.registerClass('PaytableContent', PaytableContent);
FlashLib.registerClass('PaytableControls', PaytableControls);
FlashLib.registerClass('SymbolAnimation', SymbolAnimation);
FlashLib.registerClass('PopupWin', PopupWin);
FlashLib.registerClass('BackgroundContainer', BackgroundContainer);
FlashLib.registerClass('ControllerReels', ControllerReels);
FlashLib.registerClass('ControllerFreespinsStart', ControllerFreespinsStart);
FlashLib.registerClass('ControllerFreespinsEnd', ControllerFreespinsEnd);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('ControllerPopupsBgAnimation', ControllerPopupsBgAnimation);
FlashLib.registerClass('FreespinsContainer', FreespinsContainer);
FlashLib.registerClass('TotalWinContainer', TotalWinContainer);
FlashLib.registerClass('ControllerAntisipators', ControllerAntisipators);
FlashLib.registerClass('ControllerBonusPurchase', ControllerBonusPurchase);
FlashLib.registerClass('ControllerBonusPurchaseCard', ControllerBonusPurchaseCard);
FlashLib.registerClass('ControllerConfirm', ControllerConfirm);
FlashLib.registerClass('ButtonAnimated', ButtonAnimated);
FlashLib.registerClass('ControllerFeatureChoice', ControllerFeatureChoice);
FlashLib.registerClass('ControllerFallingItem', ControllerFallingItem);
