import EntryPoint from 'Engine/EntryPoint';

export default new class XXXDataParser {
    parseInit(data) {
        let newData = {};

        newData.action = 'init';
        newData.balance = data.balance;
        newData.params = {
            betPossible: data.bets_available
        };
        newData.current = {
            bet: data.bet_amount,
        };
        newData.currencyInfo = {
            decimals: data.decimals,
            symbol: data.currency,
            currency: data.currency
        };
        newData.paytableInfo = data.slot_machine.paytable;
        newData.bonusCostMultiplier = data.bonus_buy_multiplier;
        newData.freespinsData = this._makeFreespinsData(data);
        newData.featureData = this._makeFeatureData(data);

        newData.freebets = data.freebets

        return newData;
    }

    parseSpin(data) {
        let newData = {};

        newData.action = 'spin';
        newData.balance = data.balance;
        newData.spin = {
            reels: data.spin?.reels || data.bonus.reels,
            winnings: this._makeWinnings(data),
            bet: data.bet_amount,
        };
        newData.freespinsData = this._makeFreespinsData(data);
        newData.featureData = this._makeFeatureData(data);

        newData.freebets = data.freebets

        return newData;
    }

    parsePurchaseBonusGame(data) {
        let newData = {};
        newData.action = 'buy';
        newData.balance = data.balance;
        newData.freespinsData = this._makeFreespinsData(data);

        newData.freebets = data.freebets

        return newData;
    }

    _makeWinnings(data) {
        const spinData = data.spin;
        let winnings = {};
        winnings.totalWin = 0;

            winnings.lines = [];
        if (spinData) {
            for (const lineNumber in spinData.win_lines) {
                const lineLeng = spinData.lines_icons[lineNumber];
                if(lineLeng < 3) continue;
                let line = {};
                line.path = EntryPoint.configData.payLines[lineNumber - 1].concat().splice(0, lineLeng);
                // line.line = this.linesRelation[winData.payline.number];
                line.line = lineNumber - 1;
                line.count = lineLeng;
                line.win = spinData.win_lines[lineNumber] * data.bet_amount;
                line.coef = spinData.win_lines[lineNumber];

                winnings.lines.push(line);

                winnings.totalWin = spinData.total_win;
            }
        }


        // if (data.jokers_positions?.length) {
        //     winnings.jackpotters = [];
        //     let jackpotter = {};
        //     jackpotter.symbol = 1;
        //     const pathArr = new Array(3);
        //     data.jokers_positions.forEach(reelData => {
        //         pathArr[reelData[0]] = reelData[1]+1;
        //     })
        //     jackpotter.path = pathArr;
        //     jackpotter.count = data.jokers_positions.length;
        //     // scatter.win = data.scatters_win;
        //     jackpotter.isScatter = true;
        //     winnings.jackpotters.push(jackpotter);
        //
        //     // winnings.totalWin += scatter.win;
        // }
        // if (data.jokers_positions?.length === 3) winnings.scatters = winnings.jackpotters;//temp comment



        return winnings;
    }

    _makeFreespinsData(data) {
        if (!data.freespins) {
            return null;
        }
        const freespinsData = {
            totalWin: data.freespins.freespins_total_win_amount,
            freespins: data.freespins.freespins_count,
            freespinsTriggered: data.freespins.freespins_triggered,
            isFreespinsGame: data.freespins.is_freespins,
        };
        return freespinsData;
    }

    _makeFeatureData(data) {
        if (!data.bonus) return;

        let isFeature = false;
        const list = data.bonus.map(bonusItem => {
            if (!bonusItem.isUsed) isFeature = true;
            return {
                type: bonusItem.type,
                reelIndex: bonusItem.affectedReelIndex,
                isUsed: bonusItem.isUsed
            }
        })

        return {
            list,
            isFeature
        }
    }
}
