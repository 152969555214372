import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { orangeGradientFontStyle } from '../../fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eEventTypes from '../../enums/eEventTypes';
import UiAdapter from '../../libs/game-engine/src/base/ui/uiAdapter/BaseUiAdapter';
import uiConfig from '../ui/config';
import eSymbolIndex from '../symbols/eSymbolIndex';

export default class StartScreenContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.onKeyDown = this.onKeyDown.bind(this);

    this.init();
    this.addListeners();
  }


  onResize (data) {
   if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1)
      this.content.visible = false;
      this.portretContent.visible = true;
      this.startButton.pivot.x = this.startButton.displayData.width / 2;
      this.startButton.x = this.startButton.displayData.x  + this.startButton.displayData.width / 2;
      this.startButton.scale.set(1.25);
      this.startButton.y = this.startButton.displayData.y + 320;
    } else {
      this.background.goToFrame(2)
      this.content.visible = true;
      this.portretContent.visible = false;
      this.startButton.pivot.x = 0;
      this.startButton.x = this.startButton.displayData.x;
      this.startButton.scale.set(this.startButton.displayData.scaleX);
      this.startButton.y = this.startButton.displayData.y;
    }
  }

  init() {
    this.content = this.getChildByName('content');
    this.portretContent = this.getChildByName('portretContent');
    this.background = this.getChildByName('background');
    this.visible = false;
    this.eventMode = 'static';
    this.startButton = this.getChildByName('startButton');
    makeHitArea(this.startButton);
    this.setTextsStyle();
    this.setWinAmounts();

  }

  addListeners() {
    GlobalDispatcher.addOnce(eEventTypes.EET_START_SCREEN__SHOW, this.show.bind(this));
    this.startButton.on('pointertap', this.hide, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  setTextsStyle() {
    const setFunction = (content) => {
      for (let i = 0; i < 6; i++) {
        const text = content.getChildByName(`start_screen_${i}`);
        text.style = { ...text.style, ...orangeGradientFontStyle };
      }
      const winAmount = content.getChildByName(`start_screen_max_win_amount`);
      winAmount.style = { ...winAmount.style, ...orangeGradientFontStyle };
    }
    const startButtonText = this.startButton.getChildByName('start_screen_play');
    startButtonText.style = {
      ...startButtonText.style,
      ...orangeGradientFontStyle,
      dropShadow: true,
      dropShadowBlur: 6,
      dropShadowColor: "#510754",
      dropShadowDistance: 0,
    };
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);

  }

  setWinAmounts() {
    const setFunction = (content) => {
      const maxWinField = content.getChildByName(`start_screen_max_win_amount`)
      // maxWinField.text = MoneyFormat.format(EntryPoint.GameModel.jackpotMaxWin, false); // temp comment
    }
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);
  }

  onKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.hide();
    }
  }

  show() {
    document.addEventListener('keydown', this.onKeyDown)
    this.startButton.enabled = true;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.startButton.enabled = false;
    GlobalDispatcher.dispatch(eEventTypes.EET_START_SCREEN__HIDE);
    SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
    document.removeEventListener('keydown', this.onKeyDown);
    OPWrapperService.freeBetsController.show();

    const ControllerUi = window.OPWrapperService.initUi(uiConfig);
    new UiAdapter(ControllerUi).init({
      // bonusLabelText: 'ROUNDS',
      clickSound: { soundName: eSoundType.EST_BUTTON_CLICK, volume: 0.7, group: 'default' },
      winLineConfig: {
        idToClassNameAdapter: {
          [eSymbolIndex.ESI_WILD]: 'ui_win_lines__wild',
          [eSymbolIndex.ESI_PINK_CAT]: 'ui_win_lines__pink_cat',
          [eSymbolIndex.ESI_ORANGE_CAT]: 'ui_win_lines__orange_cat',
          [eSymbolIndex.ESI_BLUE_CAT]: 'ui_win_lines__blue_cat',
          [eSymbolIndex.ESI_GREEN_CAT]: 'ui_win_lines__green_cat',
          [eSymbolIndex.ESI_A]: 'ui_win_lines__a',
          [eSymbolIndex.ESI_K]: 'ui_win_lines__k',
          [eSymbolIndex.ESI_Q]: 'ui_win_lines__q',
          [eSymbolIndex.ESI_J]: 'ui_win_lines__j',
          [eSymbolIndex.ESI_10]: 'ui_win_lines__ten',
          [eSymbolIndex.ESI_SCATTER]:'ui_win_lines__scatter',
          [eSymbolIndex.ESI_BIG_WILD]:'ui_win_lines__wild_big',
        },
      }
    });
  }
}
